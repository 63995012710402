@import "_main";

.select2-container {

  /* stylelint-disable declaration-no-important */
  //width: 100% !important;

  /* stylelint-enable declaration-no-important */
}

.select2-container .select2-selection--single {
  height: var(--x-input-height);
  font-size: var(--x-input-font-size);
  padding: 0 var(--x-input-padding-x);

  &:focus-visible {
    outline: none;
  }
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: var(--x-input-height);
  padding: 0;

  &:focus-visible {
    outline: none;
  }
}

.select2-selection:focus {
  box-shadow: 0 0 0 0.1875rem var(--x-input-focus-box-shadow-color);
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: var(--x-input-height);
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 0.438rem;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  right: 0.738rem;
}

.select2-search__field {
  &:focus-visible {
    outline: var(--x-input-focus-box-shadow-color) auto 0.063rem;
  }
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  //border: 0.063rem solid #aaa;
  box-shadow: 0 0 0 0.1875rem var(--x-input-focus-box-shadow-color);
}

.select2-container .select2-selection--multiple .select2-selection__choice {
  max-width: 100%;
  box-sizing: border-box;
  white-space: normal;
  word-wrap: break-word;
}
